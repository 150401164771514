@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    background-color:  #E0D9D3;
}


.swiper-button-next:after,
.swiper-button-prev:after {
    color: #323232
}

.swiper-pagination-progressbar .swiper-pagination-progessbar-fill{
    background: #323232 !important;
    
}

html::-webkit-scrollbar{
    display: none;
}

html{
    scrollbar-width: none;
}
.menu-x li:hover{
    color: #E0D9D3 !important;
}